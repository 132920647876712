import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Wrapper from '../wrapper';

const StyledOurPortfolio = styled.div`
  margin: 32px 0px;
  padding: 32px 0px;

  h1 {
    text-align: center;
  }

  .styled-col {
    padding: 8px;

    .image-card {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
      position: relative;

      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.36);
        z-index: 2;
        opacity: 0;
        transition: 0.5s;
      }

      img {
        margin: 0;
      }
    }

    .image-card:hover .overlay {
      opacity: 1;
    }
  }
`;

const OurPortfolio = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "websites" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const website = data.allFile.edges;

  return (
    <StyledOurPortfolio id="our-portfolio">
      <Wrapper>
        <h1>Our Portfolio</h1>
        <Row type="flex">
          {website.map((value) => (
            <Col
              lg={8}
              sm={12}
              xs={24}
              className="styled-col"
              key={value.node.id}
            >
              <div className="image-card">
                <a
                  href={`http://${value.node.base.split('-min')[0]}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overlay" />
                  <Img
                    fluid={value.node.childImageSharp.fluid}
                    alt={value.node.base}
                  />
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </Wrapper>
    </StyledOurPortfolio>
  );
};

OurPortfolio.propTypes = {};

export default OurPortfolio;
