import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Wrapper from '../wrapper';

const StyledServiceBundlesComponent = styled.div`
  margin: 64px 0px;
  padding: 64px 0px;
  background-color: ${(props) => props.theme.blackPalette.original};

  h1 {
    color: ${(props) => props.theme.whitePalette.original};
    text-align: center;
    margin-bottom: 32px;
  }

  .styled-col {
    padding: 14px;
    width: 100%;

    .styled-card {
      height: 100%;
      padding: 16px;
      background-color: ${(props) => props.theme.whitePalette.original};

      .icon-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 14px;
        margin-bottom: 24px;

        .styled-icon {
          height: 32px;
          color: ${(props) => props.theme.redPalette.light};
        }
      }

      h3 {
        text-align: center;
        color: ${(props) => props.theme.redPalette.original};
      }

      .styled-text-left {
        text-align: left;
        color: ${(props) => `${props.theme.blackPalette.dark}E6`};
      }

      p {
        margin: 0;
        font-size: 18px;
        line-height: 24px;
      }

      p > span {
        font-weight: 700;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-decoration: underline;
        font-family: Techna Sans Regular;
        letter-spacing: 1px;
      }
    }
  }
`;

const ServiceBundlesComponent = () => (
  <StyledServiceBundlesComponent id="service-bundles">
    <Wrapper>
      <Row type="flex" justify="center">
        <Col xl={20} sm={24} xs={24}>
          <h1>Service Bundles</h1>
          <Row type="flex">
            <Col lg={8} sm={24} sx={24} className="styled-col">
              <div className="styled-card">
                <h3>STARTER</h3>
                <p>
                  <span>Company website</span>
                  <br />
                  Social media page
                  <br />
                  Logo design
                </p>
              </div>
            </Col>
            <Col lg={8} sm={24} sx={24} className="styled-col">
              <div className="styled-card">
                <h3>ACCELERATOR</h3>
                <p>
                  <span>Company website</span>
                  <br />
                  Product development
                  <br />
                  (mobile or web app)
                  <br />
                  New brand plan
                  <br />
                  SEO/SEM
                  <br />
                  Communications and PR
                </p>
              </div>
            </Col>
            <Col lg={8} sm={24} sx={24} className="styled-col">
              <div className="styled-card">
                <h3>ENTERPRISE</h3>
                <p>
                  <span>Company website</span> + i11n (internationalization)
                  <br />
                  Product development
                  <br />
                  Infrastructure management
                </p>
              </div>
            </Col>
            <Col lg={8} sm={8} sx={24} className="styled-col">
              <div className="styled-card">
                <h3>OTHER SERVICES</h3>
                <p>Staffing and recruitment Developer training Documentation</p>
              </div>
            </Col>
            <Col lg={16} sm={16} sx={24} className="styled-col">
              <div className="styled-card">
                <Row type="flex" align="bottom">
                  <Col md={16} sm={24} xs={24}>
                    <h3 className="styled-text-left">
                      We can tailor-fit these for you
                    </h3>
                    <p>
                      Not sure which of these do you need? Get in touch with us
                      for a free consultation.
                    </p>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <AnchorLink href="#work-with-us">
                      WORK WITH US {'>'}
                    </AnchorLink>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  </StyledServiceBundlesComponent>
);

ServiceBundlesComponent.propTypes = {};

export default ServiceBundlesComponent;
