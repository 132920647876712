import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSectionComponent from '../components/LandingPageComponents/HeroSectionComponent';
import WhoWeAreComponent from '../components/LandingPageComponents/WhoWeAreComponent';
import ServiceBundlesComponent from '../components/LandingPageComponents/ServiceBundlesComponent';
import OurPortfolioComponent from '../components/LandingPageComponents/OurPortfolioComponent';
import WorkWithUsComponent from '../components/LandingPageComponents/WorkWithUsComponent';

import ogImage from '../../static/images/GW_logo_red.png';

const IndexPage = () => (
  <Layout>
    <SEO
      title=""
      meta={[
        {
          property: 'og:image',
          content: ogImage,
        },
        {
          property: 'og:url',
          content: 'http://gwsolutions.primephilippines.com',
        },
        {
          name: 'keywords',
          content: 'GW Solutions',
        },
      ]}
    />
    <HeroSectionComponent />
    <WhoWeAreComponent />
    <ServiceBundlesComponent />
    <OurPortfolioComponent />
    <WorkWithUsComponent />
  </Layout>
);

export default IndexPage;
