import React from 'react';
// import { Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Wrapper from '../wrapper';
import LogoComponent from '../logoComponent';

import HeroImage from '../../../static/images/hero-image-min.png';

const links = [
  { label: 'WHO WE ARE >', path: '#who-we-are' },
  { label: 'SERVICE BUNDLES >', path: '#service-bundles' },
  { label: 'OUR PORTFOLIO >', path: '#our-portfolio' },
  { label: 'WORK WITH US >', path: '#work-with-us' },
];

const StyledHeroSectionComponent = styled.div`
  background-color: ${(props) => props.theme.blackPalette.dark};
  color: ${(props) => props.theme.whitePalette.original};

  .styled-shape {
    height: 640px;
    position: absolute;
    top: 0;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }

  .black {
    width: 70%;
    z-index: 5;
    left: 0;
    background-color: ${(props) => props.theme.blackPalette.dark};
    background-image: linear-gradient(
      ${(props) => props.theme.blackPalette.dark},
      ${(props) => props.theme.blackPalette.dark},
      ${(props) => props.theme.blackPalette.original}
    );
  }

  .red {
    width: 70%;
    z-index: 4;
    left: 24px;
    background-color: ${(props) => `${props.theme.redPalette.original}99`};
  }

  .content-wrapper {
    height: 100%;
    width: 80%;

    .logo-wrapper {
      width: 100px;
      margin-bottom: 32px;
    }

    h1 {
      text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    }

    h1 > span {
      color: ${(props) => props.theme.redPalette.light};
      font-family: inherit;
    }

    h3 {
      font-family: Proxima Nova;
      margin-bottom: 32px;
    }

    .link-wrapper {
      margin: 4px 0px;

      a {
        color: inherit;
        text-decoration: underline;
        font-family: Techna Sans Regular;
        letter-spacing: 1px;
      }

      a:hover {
        color: ${(props) => props.theme.whitePalette.original};
      }
    }
  }

  .styled-hero-image {
    height: 640px;
    width: 100%;
    background-image: url(${HeroImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 576px) {
    .black,
    .red {
      width: 90%;
    }
  }
`;

const HeroSectionComponent = () => (
  <StyledHeroSectionComponent id="/">
    <div className="styled-shape red" />
    <div className="styled-shape black">
      <Wrapper align="middle" style={{ height: '100%' }}>
        <div className="content-wrapper">
          <div className="logo-wrapper">
            <LogoComponent />
          </div>
          <div>
            <h1>
              Take your online presence to a <span>whole new level</span>
            </h1>
            <h3>
              Stunning, mobile-responsive business website solutions for any
              business, for the fraction of the cost.
            </h3>
          </div>
          <div>
            {links.map((value) => (
              <div key={value.label} className="link-wrapper">
                <AnchorLink offset="120" href={value.path}>
                  {value.label}
                </AnchorLink>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </div>
    <Row type="flex">
      <Col span={12} />
      <Col span={12}>
        <div className="styled-hero-image" />
      </Col>
    </Row>
  </StyledHeroSectionComponent>
);

HeroSectionComponent.propTypes = {};

export default HeroSectionComponent;
