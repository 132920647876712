import React from 'react';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';

import Wrapper from '../wrapper';

const StyledWhoAreWeComponent = styled.div`
  margin: 64px 0px;
  padding: 64px 0px;

  .styled-content {
    display: flex;

    h4 {
      letter-spacing: 1px;
      color: ${(props) => props.theme.redPalette.original};
    }

    h1 {
      margin-bottom: 32px;
    }

    h3 {
      font-family: Proxima Nova;
      margin: 0;
    }

    .ant-divider-vertical {
      height: auto;
      width: 6px;
      margin: 0px 32px 0px 0px;
      background-color: ${(props) => props.theme.redPalette.light};
    }
  }
`;

const WhoAreWeComponent = () => (
  <StyledWhoAreWeComponent id="who-we-are">
    <Wrapper>
      <Row type="flex" justify="center">
        <Col xl={16} sm={24} xs={24}>
          <div className="styled-content">
            <Divider type="vertical" />
            <div>
              <h4>WHO WE ARE</h4>
              <h1>
                We are a web development team experienced in bringing brands and
                ventures to life.
              </h1>
              <h3>
                We work with companies from various industries, building their
                intended product and providing solutions that boost their
                audience exposure, online presence and branding.
              </h3>
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  </StyledWhoAreWeComponent>
);

WhoAreWeComponent.propTypes = {};

export default WhoAreWeComponent;
