import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Wrapper from '../wrapper';

const StyledWorkWithUsComponent = styled.div`
  margin: 64px 0px;
  padding: 64px 0px;

  .styled-header {
    padding: 0px 24px;

    h4 {
      letter-spacing: 1px;
      color: ${(props) => props.theme.redPalette.original};
    }

    h1 {
      margin-top: 16px;
      margin-bottom: 4px;
    }

    h3 {
      font-family: Proxima Nova;
      margin-bottom: 32px;
    }
  }

  .styled-col {
    padding: 8px;
    width: 100%;

    .styled-card {
      padding: 24px 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px;
      text-align: center;
      height: 100%;

      p {
        font-size: 20px;
        line-height: 24px;
        margin: 0;
      }
    }

    .last {
      padding: 8px;
    }

    .red {
      background-color: ${(props) => props.theme.redPalette.original};
      color: ${(props) => props.theme.whitePalette.original};
    }

    .white {
      background-color: ${(props) => props.theme.whitePalette.original};
    }
  }
`;

const WorkWithUsComponent = () => (
  <StyledWorkWithUsComponent id="work-with-us">
    <Wrapper>
      <Row type="flex" justify="center">
        <Col xl={18} sm={24} xs={24}>
          <div className="styled-header">
            <h4>WORK WITH US</h4>
            <h1>Your ideas, our execution.</h1>
            <h3>
              Get in touch with us today and give your idea the treatment it
              deserves - masterful, dedicated execution.
            </h3>
          </div>
          <Row>
            <Col md={16} sm={24} sx={24} className="styled-col">
              <a href="https://gwsolutions.typeform.com/to/X0BBPH">
                <div className="styled-card red">
                  <h3>Start here: Project questionnaire</h3>
                  <p>
                    Answer this quick questionnaire for us to understand your
                    needs better. Click here.
                  </p>
                </div>
              </a>
            </Col>
            <Col md={8} sm={24} sx={24} className="styled-col">
              <div className="styled-card white">
                <h3>Contact us</h3>
                <p>inquiry@gwsolutions.com</p>
                <p>(+63) 9165903894</p>
              </div>
            </Col>
            <Col md={24} sm={24} sx={24} className="styled-col">
              <div className="styled-card white last">
                <p>
                  3/F Main GreatWork Ben- Lor IT Center, Diliman, Quezon City,
                  Metro Manila
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  </StyledWorkWithUsComponent>
);

WorkWithUsComponent.propTypes = {};

export default WorkWithUsComponent;
